// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SliderComponent from './components/SliderComponent';
import NewsList from './components/NewsList';
import About from './components/About';
import Courses from './components/Courses';
import Trips from './components/Trips';
import Contact from './components/Contact';
import EquipmentReservation from './components/EquipmentReservation';
import NewsDetail from './components/NewsDetail';
import SliderDetail from './components/SliderDetail';
import StickyMenu from './components/StickyMenu';
import Footer from './components/Footer';
import './App.css';

function App() {
    return (
        <Router>
            <div className="app">
                <StickyMenu />
                <Routes>
                    <Route path="/" element={<><SliderComponent /><NewsList /></>} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/courses" element={<Courses />} />
                    <Route path="/trips" element={<Trips />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/equipment-reservation" element={<EquipmentReservation />} />
                    <Route path="/news/:id" element={<NewsDetail />} />
                    <Route path="/slider/:id" element={<SliderDetail />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;

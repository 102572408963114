import React, { useState, useEffect } from 'react';
import './StickyMenu.css';

const StickyMenu = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
            setMenuOpen(false); // Zamknij menu po rozszerzeniu ekranu
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`sticky-menu ${isSticky ? 'sticky-active' : 'transparent'}`}>
            {isMobile ? (
                <div className="dropdown-menu">
                    <button className="menu-button" onClick={toggleMenu}>
                        {menuOpen ? 'Zamknij' : 'Menu'}
                    </button>
                    <div className={`dropdown-content ${menuOpen ? 'open' : ''}`}>
                        <a href="about-us" onClick={toggleMenu}>O nas</a>
                        <a href="courses" onClick={toggleMenu}>Kursy</a>
                        <a href="trips" onClick={toggleMenu}>Wyjazdy</a>
                        <a href="contact" onClick={toggleMenu}>Kontakt</a>
                        <a href="equipment-reservation" onClick={toggleMenu}>Rezerwacja sprzętu</a>
                    </div>
                </div>
            ) : (
                <ul>
                    <li><a href="about-us">O nas</a></li>
                    <li><a href="courses">Kursy</a></li>
                    <li><a href="trips">Wyjazdy</a></li>
                    <li><a href="contact">Kontakt</a></li>
                    <li><a href="equipment-reservation">Rezerwacja sprzętu</a></li>
                </ul>
            )}
        </div>
    );
};

export default StickyMenu;

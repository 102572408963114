import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NewsList.css';

const NewsList = () => {
    const [newsList, setNewsList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch danych na starcie komponentu
        fetch("https://akpkrab.pl:443/newsItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setNewsList(result); // Ustawienie newsList po fetchu
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, []); // Pusty array sprawia, że useEffect uruchamia się tylko raz na start

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div id="news-section" className="news-list">
                {newsList.slice().reverse().map((news, index) => {
                    const reversedIndex = newsList.length - 1 - index; // Odwrócony indeks
                    return (
                        <div key={index} className={`news-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                            <div className="news-image">
                                <img src={news.image} alt={news.title} />
                            </div>
                            <div className="news-content">
                                <h3>{news.title}</h3>
                                <p>{news.text}</p>
                                <Link to={`/news/${reversedIndex}`}>Read more</Link> {/* Zmieniony link */}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
};

export default NewsList;
// src/components/NewsDetail.js

import React from 'react';
import { useParams } from 'react-router-dom';
import newsItems from '../data/newsItems';
import './SubPage.css';

const NewsDetail = () => {
    const { id } = useParams(); // Pobranie ID z URL
    const news = newsItems[id]; // Pobranie odpowiedniego newsu na podstawie ID

    if (!news) {
        return <div>News not found</div>;
    }

    return (
        <div className="subpage-container">
            <h1 className="subpage-title">{news.title}</h1>
            <div className="subpage-content">
                <img src={news.image} alt={news.title} />
                <p>{news.text}</p>
                <a href={news.link} className="button-back">Visit source</a>
            </div>
        </div>
    );
};

export default NewsDetail;

// src/components/SlideDetail.js

import React from 'react';
import { useParams } from 'react-router-dom';
import sliderItems from '../data/sliderItems';
import './SubPage.css';

const SlideDetail = () => {
    const { id } = useParams(); // Pobranie ID z URL
    const slide = sliderItems[id]; // Pobranie odpowiedniego slajdu na podstawie ID

    if (!slide) {
        return <div>Slide not found</div>;
    }

    return (
        <div className="subpage-container">
            <h1 className="subpage-title">{slide.title}</h1>
            <div className="subpage-content">
                <img src={slide.image} alt={slide.title} />
                <p>{slide.text}</p>
                <a href={slide.link} className="button-back">Visit source</a>
            </div>
        </div>
    );
};

export default SlideDetail;

// src/data/newsItems.js

const newsItems = [
    {
        title: 'First News',
        text: 'This is the first news description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
    {
        title: 'Second News',
        text: 'This is the second news description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
    {
        title: 'Third News',
        text: 'This is the third news description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
    {
        title: 'First News',
        text: 'This is the first news description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
    {
        title: 'Second News',
        text: 'This is the second news description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
    {
        title: 'Third News',
        text: 'This is the third news description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
];

export default newsItems;

// src/data/sliderItems.js

const sliderItems = [
    {
        title: 'First Slide',
        text: 'This is the first slide description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
    {
        title: 'Second Slide',
        text: 'This is the second slide description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
    {
        title: 'Third Slide',
        text: 'This is the third slide description.',
        image: 'https://via.placeholder.com/1920x1080',
        link: '#',
    },
];

export default sliderItems;
